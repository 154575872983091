.funding-note {
  background-color: lightgoldenrodyellow;
  padding: 1em;
}

.data-page {
  margin-top: 1rem;

  &__content {
  }

  // Tabs styling
  .MuiTabs-root {
    border-bottom: rgb(230, 224, 224) 1px solid;
  }
  .MuiTab-wrapper {
    flex-direction: row;
  }
  .custom-tab-label {
    padding-left: 0.5em;
  }

  .map-button {
    height: 36px;
    margin-top: 12px;
  }

  .export-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1000px) {
  .data-page {
    & > .intro {
      padding-left: 4.7rem;
    }
  }
}

@media (max-width: 600px) {
  .data-page {
    margin-top: 0.5rem;
  }
  .MuiContainer-root {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

